import { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Redirect to the specified URL
    window.location.href = 'https://abdulrubfarooqi.wixsite.com/jaagwm';
  }, []);

  return null; // You can return null or any other component here
}

export default App;
